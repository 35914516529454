<template>
	<c-form ref="form">
		<c-form-item type="hidden" name="organization_id"></c-form-item>
		<c-form-item type="input" label="组织名称" name="organization_name" required></c-form-item>
		<c-form-item type="input" label="审核人手机号码" name="check_phone" required></c-form-item>
	</c-form>
</template>

<script>
	export default {
		methods: {
			add() {
				this._edit = false;
				this.$refs.form.clear();
			},
			
			edit(id) {
				this._edit = true;
				this.request({
					url: '/yzdw/organization/detail',
					data: {
						organization_id: id
					},
					success: data => {
						this.$refs.form.set(data);
					}
				});
			},
			
			submit() {
				return new Promise(resolve => {
					this.$refs.form.submit({
						url: this._edit ? '/yzdw/organization/edit' : '/yzdw/organization/add',
						
						rule: {
							organization_name: '请填写组织名称',
							check_phone: '请填写审核人手机号码'
						},
						
						success: resolve
					});
				})
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>